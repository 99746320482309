import {
  _targetstrategy,
  targetsegment,
  archivetargetstrategy,
  updatetargetstrategy,
  deleteTargetSegment
} from './targetstrategy';
import {
  onerrorgetinterrupt,
  pairDevice,
  unpairDevice,
  onErrorPairDevice,
  onErrorUnPairDevice,
  onCheckConnection,
  generalError,
  showToastNotification
} from './signagevendors';

const customMiddlewares = [
  _targetstrategy,
  targetsegment,
  archivetargetstrategy,
  updatetargetstrategy,
  onerrorgetinterrupt,
  pairDevice,
  unpairDevice,
  deleteTargetSegment,
  onErrorPairDevice,
  showToastNotification,
  onErrorUnPairDevice,
  onCheckConnection,
  generalError
];

export default customMiddlewares

import *  as _ from 'lodash';
import {
  ONERRORGETINTERRUPT,
  ADDSIGNAGEDEVICES,
  DELETESIGNAGEDEVICES,
  ONERRORPAIRDEVICE,
  SHOWTOASTNOTIFICATION,
  ONINVALIDPAIRDEVICE,
  ONERRORUNPAIRDEVICE,
  SETCHECKCONNECTION,
  GENERALERROR
} from '../actions/signagevendors';
import { toastNotification } from '../actions/utils';
import { replaceStringTemplate } from '../utils/common';
import {
  GENERALERRORTOAST,
  FAILED,
  SUCCESS,
  SETPAIRDEVICETOAST,
  SETUNPAIRDEVICETOAST,
  ONERRORPAIRDEVICETOAST,
  ONERRORUNPAIRDEVICETOAST,
  ONINVALIDDEVICETOAST,
  ONSUCCESSCHECKCONNECTIONREACHABLE,
  ONSUCCESSCHECKCONNECTIONNOTREACHABLE
} from '../utils/toastmessages';

const onerrorgetinterrupt = (store) => (next) => (action) => {
  const { type } = action;

  if (_.isEqual(type, ONERRORGETINTERRUPT)) {
    toastNotification(GENERALERRORTOAST, FAILED);
  }
  next(action);
}

const pairDevice = (store) => (next) => (action) => {
  const { type, typeOfPairing } = action;
  if (_.isEqual(type, ADDSIGNAGEDEVICES) && !(typeOfPairing === 'MULTIPLE')) {
    toastNotification(SETPAIRDEVICETOAST, SUCCESS);
  }
  next(action);
}

const onErrorPairDevice = (store) => (next) => (action) => {
  const { type } = action;

  if (_.isEqual(type, ONERRORPAIRDEVICE)) {
    toastNotification(ONERRORPAIRDEVICETOAST, FAILED);
  }

  if (_.isEqual(type, ONINVALIDPAIRDEVICE)) {
    toastNotification(ONINVALIDDEVICETOAST, FAILED)
  }
  next(action);
}

const unpairDevice = (store) => (next) => (action) => {
  const { type } = action;

  if (_.isEqual(type, DELETESIGNAGEDEVICES)) {
    toastNotification(SETUNPAIRDEVICETOAST, SUCCESS);
  }
  next(action);
}

const onErrorUnPairDevice = (store) => (next) => (action) => {
  const { type } = action;

  if (_.isEqual(type, ONERRORUNPAIRDEVICE)) {
    toastNotification(ONERRORUNPAIRDEVICETOAST, FAILED);
  }
  next(action);
}

const onCheckConnection = (store) => (next) => (action) => {
  const { type, isReachable, signagevendorplayer_name } = action;

  if (_.isEqual(type, SETCHECKCONNECTION)) {

    if (isReachable) {
      toastNotification(replaceStringTemplate(ONSUCCESSCHECKCONNECTIONREACHABLE, signagevendorplayer_name), SUCCESS);
    }
    else {
      toastNotification(replaceStringTemplate(ONSUCCESSCHECKCONNECTIONNOTREACHABLE, signagevendorplayer_name), FAILED);
    }

  }
  next(action);
}

const generalError = (store) => (next) => (action) => {
  const { type } = action;

  if (_.isEqual(type, GENERALERROR)) {
    toastNotification(GENERALERRORTOAST, FAILED);
  }
  next(action);
}

const showToastNotification = (store) => (next) => (action) => {
  const { payload, type } = action;
  if (payload && typeof payload.successCount !== 'undefined' && typeof payload.errorCount !== 'undefined') {
    const message = `Success: ${payload.successCount}, Errors: ${payload.errorCount}`;
    if (type === SHOWTOASTNOTIFICATION) {
      toastNotification(message, SUCCESS);
    }
  } else {}
  next(action);
};

export {
  onerrorgetinterrupt,
  pairDevice,
  unpairDevice,
  onErrorPairDevice,
  onErrorUnPairDevice,
  onCheckConnection,
  generalError,
  showToastNotification
};
